/* index.css */
:root {
  --font-family: 'McLaren';
  --font-size-base: 20px;

  --large-title: 3rem;
  --title1: 2.5rem;
  --title2: 2rem;
  --title3: 1.5rem;
  --headline: 1.4rem;
  --body: 1.2rem;
  --button: 1.5rem;
  --callout: 1.5rem;
  --subhead: 1rem;
  --footnote: 0.95rem;
  --body-small: 0.9rem;

  --font-color-primary: #484848;
  --font-color-secondary: #717171;
  --primary-color: #FF5A5F;
  --border-color: #ddd;
}

/* Typography Classes */
.text-large-title {
  font-size: var(--large-title);
  font-weight: 600;
  line-height: 1.2;
}

.text-title-1 {
  font-size: var(--title1);
  font-weight: 400;
  line-height: 1.3;
}

.text-title-2 {
  font-size: var(--title2);
  font-weight: 500;
  line-height: 1.3;
}

.text-title-3 {
  font-size: var(--title3);
  font-weight: 400;
  line-height: 1.4;
}

.text-headline {
  font-size: var(--headline);
  font-weight: 400;
  line-height: 1.4;
}

.text-body {
  font-size: var(--body);
  font-weight: 400;
  line-height: 1.5;
}

.text-button {
  font-size: var(--button);
  font-weight: 500;
  line-height: 1.4;
}

.text-callout {
  font-size: var(--callout);
  font-weight: 400;
  line-height: 1.4;
}

.text-subhead {
  font-size: var(--subhead);
  font-weight: 400;
  line-height: 1.4;
}

.text-footnote {
  font-size: var(--footnote);
  font-weight: 400;
  line-height: 1.4;
}

.text-body-small {
  font-size: var(--body-small);
  font-weight: 400;
  line-height: 1.4;
}

/* Base HTML elements - applying the same styles directly */
h1 {
  font-size: var(--large-title);
  font-weight: 600;
  line-height: 1.2;
}

h2 {
  font-size: var(--title1);
  font-weight: 400;
  line-height: 1.3;
}

h3 {
  font-size: var(--title2);
  font-weight: 500;
  line-height: 1.3;
}

h4,
.form-label {
  font-size: var(--title3);
  font-weight: 400;
  line-height: 1.4;
}


.prelaunch-bg-image {
  min-height: 100vh;
  flex-direction: column;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  width: 100vw;
  color: '#FFFFFF';
  position: relative;
}

.prelaunch-bg-image-tint {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #23232344;
  /* z-index: 10; */
  width: 100vw;
  height: 100vh;
}

p,
input,
.nav-item,
.airbnb-home {
  font-size: var(--body);
  font-weight: 400;
  line-height: 1.5;
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .prelaunch-bg-image {
    background-position-x: 70%;
    position: fixed;
  }
}

/* Tablet */

@media only screen and (min-width: 768px) and (max-width: 991px) {}

/* Computer/Desktop Monitor */

@media only screen and (min-width: 992px) and (max-width: 1199px) {}

/* Large Monitor */

@media only screen and (min-width: 1200px) and (max-width: 1919px) {}

/* WideScreen Monitor */
@media only screen and (min-width: 1920px) {}